/* tslint:disable */
/* eslint-disable */

import * as APITypes from "../models/API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createWeblab = /* GraphQL */ `mutation CreateWeblab(
  $input: CreateWeblabInput!
) {
  createWeblab(input: $input) {
    id
    name
    description
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWeblabMutationVariables,
  APITypes.CreateWeblabMutation
>;
export const createTeamWeblab = /* GraphQL */ `mutation CreateTeamWeblab(
  $input: CreateTeamWeblabInput!
) {
  createTeamWeblab(input: $input) {
    id
    weblab_id
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateTeamWeblabMutationVariables,
    APITypes.CreateTeamWeblabMutation
>;

export const updateTeamWeblab = /* GraphQL */ `mutation UpdateTeamWeblab(
  $input: UpdateTeamWeblabInput!
) {
  updateTeamWeblab(input: $input) {
    id
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateTeamWeblabMutationVariables,
    APITypes.UpdateTeamWeblabMutation
>;
export const deleteWeblab = /* GraphQL */ `mutation DeleteWeblab(
  $input: DeleteWeblabInput!
) {
  deleteWeblab(input: $input) {
    id
    name
    description
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteWeblabMutationVariables,
    APITypes.DeleteWeblabMutation
>;
export const deleteTeamWeblab = /* GraphQL */ `mutation DeleteTeamWeblab(
  $input: DeleteTeamWeblabInput!
) {
  deleteTeamWeblab(input: $input) {
    id
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteTeamWeblabMutationVariables,
    APITypes.DeleteTeamWeblabMutation
>;
export const updateWeblab = /* GraphQL */ `mutation UpdateWeblab(
  $input: UpdateWeblabInput!
) {
  updateWeblab(input: $input) {
    id
    name
    description
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateWeblabMutationVariables,
    APITypes.UpdateWeblabMutation
>;
export const createUserPreference = /* GraphQL */ `mutation CreateUserPreference(
  $input: CreateUserPreferenceInput!
) {
  createUserPreference(input: $input) {
    id
    user_id
    team_weblab_id
    watch_flag
    comments
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateUserPreferenceMutationVariables,
    APITypes.CreateUserPreferenceMutation
>;
export const createTeamPreference = /* GraphQL */ `mutation CreateTeamPreference(
  $input: CreateTeamPreferenceInput!
) {
  createTeamPreference(input: $input) {
    id
    team_id
    team_weblab_id
    watch_flag
    comments
    additional_columns
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTeamPreferenceMutationVariables,
  APITypes.CreateTeamPreferenceMutation
>;
export const updateTeamPreference = /* GraphQL */ `mutation UpdateTeamPreference(
  $input: UpdateTeamPreferenceInput!
) {
  updateTeamPreference(input: $input) {
    id
    team_id
    team_weblab_id
    watch_flag
    comments
    additional_columns
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTeamPreferenceMutationVariables,
  APITypes.UpdateTeamPreferenceMutation
>;

export const updateTeam = /* GraphQL */ `mutation UpdateTeam(
  $input: UpdateTeamInput!
) {
  updateTeam(input: $input) {
    id
    name
    custom_attributes
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTeamMutationVariables,
  APITypes.UpdateTeamMutation
>;
export const updateTeamUserMap = /* GraphQL */ `mutation UpdateTeamUserMap(
  $input: UpdateTeamUserMapInput!
) {
  updateTeamUserMap(input: $input) {
    team_id
    user_id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTeamUserMapMutationVariables,
  APITypes.UpdateTeamUserMapMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
) {
  createUser(input: $input) {
    id
    user_name
    attributes
    login_times
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const createUserRequest = /* GraphQL */ `mutation CreateUserRequest(
  $input: CreateUserRequestInput!
) {
  createUserRequest(input: $input) {
    team_id
    user_id
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserRequestMutationVariables,
  APITypes.CreateUserRequestMutation
>;
export const handleUserRequest = /* GraphQL */ `mutation HandleUserRequest(
  $input: HandleUserRequestInput!
) {
  handleUserRequest(input: $input) {
    team_id
    user_id
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.HandleUserRequestMutationVariables,
  APITypes.HandleUserRequestMutation
>;
export const moveTeamWeblabs = /* GraphQL */ `mutation MoveTeamWeblabs(
  $input: MoveTeamWeblabsInput!
) {
  moveTeamWeblabs(input: $input) {
    count
    __typename
  }
}
` as GeneratedMutation<
  APITypes.MoveTeamWeblabsMutationVariables,
  APITypes.MoveTeamWeblabsMutation
>;
export const writeAccessRequests = /* GraphQL */ `mutation WriteAccessRequests(
  $input: WriteAccessRequestsInput!
) {
  writeAccessRequests(input: $input) {
    message_id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.WriteAccessRequestsMutationVariables,
  APITypes.WriteAccessRequestsMutation
>;
