import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Configs,
  CreateUserInput,
  HandleUserRequestInput,
  Metadata,
  Users,
  WriteAccessRequestsInput,
} from "../models/API";
import {
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Grid,
  Box,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  createUser,
  handleUserRequest,
  writeAccessRequests,
} from "../graphql/mutations";
import { generateClient } from "aws-amplify/api";

interface UsersDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (item: Users) => void;
  metaData: Metadata;
  configs: Configs;
  initialData: Users;
}

interface FormErrors {
  users: string[];
}

const UsersDialog: React.FC<UsersDialogProps> = ({
  isOpen,
  onClose,
  onSave,
  metaData,
  configs,
  initialData,
}) => {
  const [newUsers, setNewUsers] = useState<string[]>(
    initialData.user_ids.map((id) => configs.usersDict[id])
  );
  const [newPendingUsers, setNewPendingUsers] = useState<string[]>(
    initialData.pending_user_ids.map((id) => configs.usersDict[id])
  );
  const [errors, setErrors] = useState<FormErrors>({ users: [] });
  const client = generateClient();
  const [notifiedUsers, setNotifiedUsers] = useState<Set<number>>(new Set());

  useEffect(() => {
    setNewUsers(initialData.user_ids.map((id) => configs.usersDict[id]));
    setNewPendingUsers(
      initialData.pending_user_ids.map((id) => configs.usersDict[id])
    );
  }, [initialData, metaData, configs.usersDict]);

  if (!isOpen) {
    return null;
  }

  const handleAddNewUser = () => {
    // @ts-ignore
    setNewUsers([...newUsers, ""]);
  };

  const handleNewUserChange = (index: number, value: string) => {
    const updatedUsers = [...newUsers];
    updatedUsers[index] = value;
    setNewUsers(updatedUsers);
  };
  const validateForm = () => {
    let counter: number = 0;
    let tempErrors: FormErrors = { users: [] };
    newUsers.forEach((element: any, index: any) => {
      tempErrors.users.push("");
      element = element.trim(); // trim space
      if (!element || element.length === 0 || element.indexOf(" ") >= 0) {
        tempErrors.users[index] =
          "User alias is required and cannot contain any whitespace";
        counter = counter + 1;
      }
    });
    setErrors(tempErrors);
    return counter === 0; // Return true if no errors
  };

  const handleRemoveUser = (e: any, index: any) => {
    const updatedUsers = [...newUsers];
    updatedUsers.splice(index, 1);
    setNewUsers(updatedUsers);
  };

  const notifyUser = async (user_name: string) => {
    try {
      const user_id = configs.userIDsDict[user_name];
      setNotifiedUsers(notifiedUsers.add(user_id));
      const notificationInput: WriteAccessRequestsInput = {
        user_id: user_id,
        user_name: user_name,
        team_id: initialData.team_id,
        team_name: configs.teamsDict[initialData.team_id],
      };
      await client.graphql({
        query: writeAccessRequests,
        variables: {
          input: notificationInput,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleApproveUser = async (e: any, index: any) => {
    const user_name = newPendingUsers[index];
    const updatedPendingUsers = [...newPendingUsers];
    updatedPendingUsers.splice(index, 1);
    setNewPendingUsers(updatedPendingUsers);
    try {
      const input: HandleUserRequestInput = {
        user_id: configs.userIDsDict[user_name],
        team_id: initialData.team_id,
        status: 0, // approved
        updated_by: initialData.current_user_id,
      };
      await client.graphql({
        query: handleUserRequest,
        variables: {
          input: input,
        },
      });
      setNewUsers([...newUsers, user_name]);
      // notification
      await notifyUser(user_name);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDenyUser = async (e: any, index: any) => {
    const user_name = newPendingUsers[index];
    const updatedPendingUsers = [...newPendingUsers];
    updatedPendingUsers.splice(index, 1);
    try {
      const input: HandleUserRequestInput = {
        user_id: configs.userIDsDict[user_name],
        team_id: initialData.team_id,
        status: 2, // denied
        updated_by: initialData.current_user_id,
      };
      await client.graphql({
        query: handleUserRequest,
        variables: {
          input: input,
        },
      });
      setNewPendingUsers(updatedPendingUsers);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSave = async (e: React.FormEvent) => {
    if (validateForm()) {
      // If user doesn't exist, create the user
      let userIDs: number[] = [];
      for (let user of newUsers) {
        try {
          user = user.trim();
          if (user in configs.userIDsDict) {
            // user exists
            userIDs.push(configs.userIDsDict[user]);
          } else {
            const input: CreateUserInput = {
              user_name: user,
            };
            const result = await client.graphql({
              query: createUser,
              variables: {
                input: input,
              },
            });
            const userData = result.data.createUser;
            configs.userIDsDict[user] = userData.id;
            configs.usersDict[userData.id] = user;
            userIDs.push(userData.id);
          }
        } catch (err) {
          console.error(err);
        }
      }
      let users: Users = {
        team_id: 0,
        current_user_id: 0,
        user_ids: [],
        pending_user_ids: [],
      };
      for (let id of userIDs) {
        if (!initialData.user_ids.includes(id) && !notifiedUsers.has(id)) {
          // notification
          await notifyUser(configs.usersDict[id]);
        }
      }
      users.user_ids = userIDs;
      onSave(users);
      handleClose();
    }
  };
  const handleClose = () => {
    setNewUsers(initialData.user_ids.map((id) => configs.usersDict[id]));
    setErrors({ users: [] });
    setNotifiedUsers(new Set());
    onClose();
  };

  // @ts-ignore
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Users</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {newUsers.map((element: string, index: any) => (
              <Box alignItems={"center"} key={index}>
                <Tooltip title={"Read Only"} placement={"bottom"}>
                  <TextField
                    size="small"
                    margin={"dense"}
                    label={"No"}
                    type={"text"}
                    value={index + 1}
                    InputProps={{
                      readOnly: true,
                      style: {
                        color: "gray", // set font color to gray
                        width: "80px",
                      },
                    }}
                  />
                </Tooltip>
                <TextField
                  size="small"
                  margin={"dense"}
                  label={"User Alias"}
                  type={"text"}
                  value={element}
                  onChange={(e) => handleNewUserChange(index, e.target.value)}
                  InputProps={{
                    style: {
                      width: "150px",
                    },
                  }}
                  error={errors.users.length > index && !!errors.users[index]}
                  helperText={
                    errors.users.length <= index ? "" : errors.users[index]
                  }
                />
                <Tooltip title={"Remove User"} placement={"right-end"}>
                  <IconButton
                    sx={{ width: 60, height: 60 }}
                    onClick={(e) => handleRemoveUser(e, index)}
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            ))}
          </Grid>
        </Grid>
        <Tooltip title={"Add User"} placement={"right-end"}>
          <IconButton size={"large"} onClick={handleAddNewUser} color="primary">
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
        <Divider style={{ margin: "20px 0" }} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {newPendingUsers.map((element: string, index: any) => (
              <Box alignItems={"center"} key={index}>
                <Tooltip title={"Read Only"} placement={"bottom"}>
                  <TextField
                    size="small"
                    margin={"dense"}
                    label={"No"}
                    type={"text"}
                    value={index + 1}
                    InputProps={{
                      readOnly: true,
                      style: {
                        color: "gray", // set font color to gray
                        width: "80px",
                      },
                    }}
                  />
                </Tooltip>
                <TextField
                  size="small"
                  margin={"dense"}
                  label={"User Alias"}
                  type={"text"}
                  value={element}
                  InputProps={{
                    style: {
                      width: "150px",
                    },
                  }}
                />
                <Tooltip title={"Approve Request"} placement={"right-end"}>
                  <IconButton
                    sx={{ width: 60, height: 60 }}
                    onClick={(e) => handleApproveUser(e, index)}
                    color="primary"
                  >
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Deny Request"} placement={"right-end"}>
                  <IconButton
                    sx={{ width: 60, height: 60 }}
                    onClick={(e) => handleDenyUser(e, index)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UsersDialog;
