/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useCallback, useState, useRef } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import {
  ColDef,
  GridApi,
  PinnedRowDataChangedEvent,
  SelectionChangedEvent,
  ModuleRegistry,
  IMultiFilterParams,
  IDateFilterParams,
  ISetFilterParams,
  GridReadyEvent,
} from "@ag-grid-community/core";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { SideBarModule } from "@ag-grid-enterprise/side-bar";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { LicenseManager } from "@ag-grid-enterprise/core";

import {
  Badge,
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import StarIcon from "@mui/icons-material/Star";
import SettingsIcon from "@mui/icons-material/Settings";
import RefreshIcon from "@mui/icons-material/Refresh";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DownloadIcon from "@mui/icons-material/Download";
import PeopleIcon from "@mui/icons-material/People";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

import { generateClient } from "aws-amplify/api";

import AddWeblabModal from "./AddWeblabModal";
import ConfirmationDialog from "./ConfirmationDialog";
import {
  createWeblab,
  updateWeblab,
  createTeamWeblab,
  updateTeamWeblab,
  deleteTeamWeblab,
  createTeamPreference,
  updateTeamPreference,
  updateTeam,
  updateTeamUserMap,
  createUser,
} from "../graphql/mutations";
import {
  listWeblabs,
  getMetadata,
  getTeamByTeamId,
  getTeams,
  getAnalysisStartDates,
  getWeblabMetrics,
} from "../graphql/queries";
import {
  type UpdateWeblabInput,
  type Metadata,
  type ListTeamWeblabsInput,
  type TeamWeblab,
  type WeblabRowType,
  type UpdateTeamWeblabInput,
  type DeleteTeamWeblabInput,
  type CreateWeblabInput,
  type CreateTeamWeblabInput,
  type Configs,
  type GetTeamPreferencesInput,
  type CreateTeamPreferenceInput,
  type UpdateTeamPreferenceInput,
  type UpdateTeamInput,
  type TeamSettings,
  type GetUsersByTeamIdInput,
  type UpdateTeamUserMapInput,
  type GetTeamByTeamIdInput,
  type Team,
  type Users,
  type CreateUserInput,
  type GetAnalysisStartDatesInput,
  type GetWeblabMetricsInput,
  type User,
} from "../models/API";
import { Resizable } from "re-resizable";
import TeamSettingsDialog from "./TeamSettingsDialog";
import UsersDialog from "./UsersDialog";
import {
  currencyFormatter,
  numberFormatter,
  covertUtcToLocal,
} from "./utils/formatters";
import DetailsPanel from "./DetailsPanel";
import ErrorModal, { getErrorMessage } from "./ErrorModal";
import MessageModal from "./MessageModal";
import { FlagCellRenderer } from "./utils/FlagIcon";
import { StatusCellRenderer } from "./utils/StatusIcon";
import HelpCenter from "./HelpCenter";
import RequestAccessDialog from "./RequestAccessDialog";
import InfoModal from "./InfoModal";
import AdminDialog from "./AdminDialog";

LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-062883}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Amazon}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Advertising_Weblab_Tracker}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Advertising_Weblab_Tracker}_need_to_be_licensed___{Advertising_Weblab_Tracker}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{31_August_2025}____[v3]_[01]_MTc1NjU5NDgwMDAwMA==d0ae68cdf8c6485d08f01bf537d9a257"
);

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  SetFilterModule,
  SideBarModule,
  MultiFilterModule,
  ExcelExportModule,
  RangeSelectionModule,
]);

const client = generateClient();

interface WeblabGridComponentProps {
  isOpen: boolean;
  currentUserName: string;
  userConfig: User | null;
  currentTeamId: number;
  teamConfig: Team;
}

// @ts-ignore
const WeblabGridComponent: React.FC<WeblabGridComponentProps> = ({
  isOpen,
  currentUserName,
  userConfig,
  currentTeamId,
  teamConfig,
}) => {
  const [error, setError] = useState<any>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [rowData, setRowData] = useState<WeblabRowType[]>([]);
  const [metadata, setMetadata] = useState<Metadata | null>(null);
  const [teams, setTeams] = useState<Team[]>([]);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [configs, setConfigs] = useState<Configs>({
    usersDict: {},
    userIDsDict: {},
    pageTypesDict: {},
    teamsDict: {},
    teamsCustomDict: {},
    teamsUsersDict: {},
    teamsPendingUsersDict: {},
    usersTeamsDict: {},
    deviceTypesDict: {},
    experimentTypesDict: {},
    marketplacesDict: {},
    marketplaceIdsDict: {},
    marketplaceDisplayOrderDict: {},
    regionDict: {},
    statusesDict: {},
    statusIDsDict: {},
  });
  const [refreshFlag, setRefresh] = useState(false);
  const [metaRefreshFlag, setMetaRefresh] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  let [editingRow, setEditingRow] = useState(null);
  let [showWatchList, setShowWatchList] = useState(false);
  let [showHelp, setShowHelp] = useState(
    userConfig?.login_times === undefined || userConfig?.login_times < 10
  );
  const [selectedRowData, setSelectedRowData] = useState<WeblabRowType | null>(
    null
  );
  const gridRef = useRef<GridApi | null>(null);
  const isFirstRender = useRef(true);
  const isUpdatedByForm = useRef(false);
  const teamCustomAttributes = JSON.parse(
    teamConfig?.custom_attributes || "{}"
  );
  let [showCurrentYear, setShowCurrentYear] = useState<boolean>(true);
  const userAttributes = JSON.parse(userConfig?.attributes || "{}");
  const [infoMessage, setInfoMessage] = useState<string | null>(null);

  // Define the column definitions
  const initialColumnDefs: ColDef[] = [
    {
      headerName: "ID",
      width: 80,
      field: "id",
      sortable: true,
      filter: true,
      cellDataType: "number",
    },
    {
      headerName: "Team ID",
      field: "team_id",
      sortable: true,
      filter: false,
      hide: true,
    },
    {
      headerName: "Weblab ID",
      field: "name",
      sortable: true,
      filter: true,
      width: 300,
    },
    {
      headerName: "Weblab Team",
      field: "weblab_team",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Owners",
      field: "owners",
      sortable: true,
      filter: true,
      width: 220,
    },
    {
      headerName: "Weblab ID ID",
      field: "weblab_id",
      sortable: true,
      filter: false,
      hide: true,
    },
    {
      headerName: "Short Description",
      field: "description",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Deep Dive Link",
      field: "deep_dive_link",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Global Initiative Name",
      field: "global_init_name",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Experiment Type",
      field: "experiment_type",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Experiment Type ID",
      field: "experiment_type_id",
      sortable: true,
      filter: false,
      hide: true,
    },
    {
      headerName: "Marketplace ID",
      field: "marketplace",
      sortable: true,
      filter: true,
      cellRenderer: FlagCellRenderer,
    },
    {
      headerName: "Marketplace ID ID",
      field: "marketplace_id",
      sortable: true,
      filter: false,
      hide: true,
    },
    {
      headerName: "Is Smoke Test",
      field: "smoke_test",
      sortable: true,
      filter: true,
      cellDataType: "boolean",
    },
    {
      headerName: "Baseline Treatment",
      field: "control",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Experiment Treatment",
      field: "treatment",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Experiment Start Date",
      field: "start_date",
      sortable: true,
      filter: false,
      hide: true,
    },
    {
      headerName: "Experiment Start Date",
      field: "start_date_date",
      sortable: true,
      filter: true,
      cellDataType: "date",
      editable: true,
      cellStyle: { backgroundColor: "#bbdefb" }, // Blue background
    },
    {
      headerName: "Analysis Duration",
      field: "analysis_duration",
      sortable: true,
      filter: true,
      cellDataType: "number",
    },
    {
      headerName: "Page Type",
      field: "page_type",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Page Type ID",
      field: "page_type_id",
      sortable: true,
      filter: false,
      hide: true,
    },
    {
      headerName: "Gating Weblab",
      field: "is_gating",
      sortable: true,
      filter: true,
      cellDataType: "boolean",
    },
    {
      headerName: "Device",
      field: "device",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Device Type ID",
      field: "device_type_id",
      sortable: true,
      filter: false,
      hide: true,
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      filter: true,
      cellRenderer: StatusCellRenderer,
      editable: true,
      cellStyle: { backgroundColor: "#bbdefb" }, // Blue background
    },
    {
      headerName: "Status ID",
      field: "status_id",
      sortable: true,
      filter: false,
      hide: true,
    },
    {
      headerName: "Expected Launch Date",
      field: "launch_date",
      sortable: true,
      filter: false,
      hide: true,
    },
    {
      headerName: "Expected Launch Date",
      field: "launch_date_date",
      sortable: true,
      filter: true,
      cellDataType: "date",
      editable: true,
      cellStyle: { backgroundColor: "#bbdefb" }, // Blue background
    },
    {
      headerName: "Actual Launch Date",
      field: "actual_launch_date",
      sortable: true,
      filter: false,
      hide: true,
    },
    {
      headerName: "Actual Launch Date",
      field: "actual_launch_date_date",
      sortable: true,
      filter: true,
      cellDataType: "date",
      editable: true,
      cellStyle: { backgroundColor: "#bbdefb" }, // Blue background
    },
    {
      headerName: "Traffic Allocation(%) to Experiment",
      field: "gating_weblab",
      sortable: true,
      filter: true,
      cellDataType: "number",
    },
    {
      headerName: "Shared Weblab",
      field: "is_shared_weblab",
      sortable: true,
      filter: true,
      cellDataType: "boolean",
    },
    {
      headerName: "Revenue Share(%)",
      field: "rev_share",
      sortable: true,
      filter: true,
      cellDataType: "number",
    },
    {
      headerName: "Expected Revenue Impact",
      field: "revenue_impact_planned_weblab",
      sortable: true,
      filter: true,
      cellDataType: "number",
      valueFormatter: (params) => currencyFormatter(params.value),
    },
    {
      headerName: "Irrelevance Rate Control(%)",
      field: "irrelevance_rate_control",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Irrelevance Rate Treatment(%)",
      field: "irrelevance_rate_treatment",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Metrics Manually Supplied",
      field: "is_manual_weblab",
      sortable: true,
      filter: true,
      cellDataType: "boolean",
    },
    {
      headerName: "Analysis Start Date",
      field: "analysis_start_date",
      sortable: true,
      filter: false,
      hide: true,
    },
    {
      headerName: "Analysis Start Date",
      field: "analysis_start_date_date",
      sortable: true,
      filter: true,
      cellDataType: "date",
    },
    {
      headerName: "Analysis End Date",
      field: "analysis_end_date",
      sortable: true,
      filter: false,
      hide: true,
    },
    {
      headerName: "Analysis End Date",
      field: "analysis_end_date_date",
      sortable: true,
      filter: true,
      cellDataType: "date",
    },
    {
      headerName: "APT Job IDs",
      field: "job_ids",
      sortable: true,
      filter: true,
    },
    {
      headerName: "pIrrel Human Relevance Audit",
      field: "pirrel_human_relevance_audit",
      sortable: true,
      filter: true,
    },
    {
      headerName: "CTR",
      field: "ctr",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Coverage",
      field: "coverage",
      sortable: true,
      filter: true,
    },
    {
      headerName: "CPC",
      field: "cpc",
      sortable: true,
      filter: true,
    },
    {
      headerName: "ACOS",
      field: "acos",
      sortable: true,
      filter: true,
    },
    {
      headerName: "SP Revenue: probability",
      field: "sp_revenue_prob",
      sortable: true,
      filter: true,
      cellDataType: "number",
    },
    {
      headerName: "SP Revenue: lift(%)",
      field: "sp_revenue_lift",
      sortable: true,
      filter: true,
      cellDataType: "number",
    },
    {
      headerName: "SP Revenue: impact",
      field: "sp_revenue_impact",
      sortable: true,
      filter: true,
      cellDataType: "number",
      valueFormatter: (params) => currencyFormatter(params.value),
    },
    {
      headerName: "OPS: probability",
      field: "ops_prob",
      sortable: true,
      filter: true,
      cellDataType: "number",
    },
    {
      headerName: "OPS: lift(%)",
      field: "ops_lift",
      sortable: true,
      filter: true,
      cellDataType: "number",
    },
    {
      headerName: "OPS: impact",
      field: "ops_impact",
      sortable: true,
      filter: true,
      cellDataType: "number",
      valueFormatter: (params) => currencyFormatter(params.value),
    },
    {
      headerName: "GCCP: probability",
      field: "gccp_prob",
      sortable: true,
      filter: true,
      cellDataType: "number",
    },
    {
      headerName: "GCCP: lift(%)",
      field: "gccp_lift",
      sortable: true,
      filter: true,
      cellDataType: "number",
    },
    {
      headerName: "GCCP: impact",
      field: "gccp_impact",
      sortable: true,
      filter: true,
      cellDataType: "number",
      valueFormatter: (params) => currencyFormatter(params.value),
    },
    {
      headerName: "Net CP: probability",
      field: "net_cp_prob",
      sortable: true,
      filter: true,
      cellDataType: "number",
    },
    {
      headerName: "Net CP: lift(%)",
      field: "net_cp_lift",
      sortable: true,
      filter: true,
      cellDataType: "number",
    },
    {
      headerName: "Net CP: impact",
      field: "net_cp_impact",
      sortable: true,
      filter: true,
      cellDataType: "number",
      valueFormatter: (params) => currencyFormatter(params.value),
    },
    {
      headerName: "SP Clicks: probability",
      field: "sp_clicks_prob",
      sortable: true,
      filter: true,
      cellDataType: "number",
    },
    {
      headerName: "SP Clicks: lift(%)",
      field: "sp_clicks_lift",
      sortable: true,
      filter: true,
      cellDataType: "number",
    },
    {
      headerName: "SP Clicks: impact",
      field: "sp_clicks_impact",
      sortable: true,
      filter: true,
      cellDataType: "number",
      valueFormatter: (params) => numberFormatter(params.value),
    },
    {
      headerName: "Paid Units: probability",
      field: "paid_units_prob",
      sortable: true,
      filter: true,
      cellDataType: "number",
    },
    {
      headerName: "Paid Units: lift(%)",
      field: "paid_units_lift",
      sortable: true,
      filter: true,
      cellDataType: "number",
    },
    {
      headerName: "Paid Units: impact",
      field: "paid_units_impact",
      sortable: true,
      filter: true,
      cellDataType: "number",
      valueFormatter: (params) => numberFormatter(params.value),
    },
    {
      headerName: "CSales: probability",
      field: "csales_prob",
      sortable: true,
      filter: true,
      cellDataType: "number",
    },
    {
      headerName: "CSales: lift(%)",
      field: "csales_lift",
      sortable: true,
      filter: true,
      cellDataType: "number",
    },
    {
      headerName: "CSales: impact",
      field: "csales_impact",
      sortable: true,
      filter: true,
      cellDataType: "number",
      valueFormatter: (params) => currencyFormatter(params.value),
    },
    {
      headerName: "SB Revenue: probability",
      field: "sb_revenue_prob",
      sortable: true,
      filter: true,
      cellDataType: "number",
    },
    {
      headerName: "SB Revenue: lift(%)",
      field: "sb_revenue_lift",
      sortable: true,
      filter: true,
      cellDataType: "number",
    },
    {
      headerName: "SB Revenue: impact",
      field: "sb_revenue_impact",
      sortable: true,
      filter: true,
      cellDataType: "number",
      valueFormatter: (params) => currencyFormatter(params.value),
    },
    {
      headerName: "Creator",
      field: "user_name",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Creator ID",
      field: "user_id",
      sortable: true,
      filter: false,
      hide: true,
    },
    {
      headerName: "Last Updated By User ID",
      field: "last_updated_by",
      sortable: true,
      filter: false,
      hide: true,
    },
    {
      headerName: "Last Updated By",
      field: "last_updated_by_user_name",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Last Updated Time",
      field: "updated_ts",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Comments",
      field: "comments",
      width: 200,
      sortable: true,
      filter: "agTextColumnFilter",
      editable: true,
      cellStyle: { backgroundColor: "#bbdefb" }, // Blue background
    },
    {
      headerName: "Watch List",
      field: "favorite",
      width: 50,
      cellRenderer: (params: any) => (
        <Tooltip
          title={
            params.data.favorite
              ? "Remove from watch list"
              : "Add to watch list"
          }
          placement={"top"}
        >
          <IconButton
            onClick={() => onToggleFavorite(params)}
            color={params.data.favorite ? "primary" : "default"}
            aria-label="Favorite"
            style={{ justifyContent: "center", display: "flex", width: "100%" }}
          >
            <StarIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ),
      cellStyle: { texAlign: "center", backgroundColor: "#bbdefb" }, // Blue background
    },
    {
      headerName: "",
      field: "delete",
      width: 50,
      cellRenderer: () => (
        <Tooltip title="Delete" placement={"top"}>
          <IconButton
            onClick={() => removeSelected()}
            color="default"
            aria-label="delete"
            style={{ justifyContent: "center", display: "flex", width: "100%" }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ),
      cellStyle: { texAlign: "center", backgroundColor: "#bbdefb" }, // Blue background
    },
    {
      headerName: "",
      field: "edit",
      width: 50,
      cellRenderer: () => (
        <Tooltip title="Edit" placement={"top"}>
          <IconButton
            onClick={() => editSelected()}
            color="default"
            aria-label="edit"
            style={{ justifyContent: "center", display: "flex", width: "100%" }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ),
      cellStyle: { texAlign: "center", backgroundColor: "#bbdefb" }, // Blue background
    },
  ];
  const [columnDefs, setColumnsDefs] = useState(initialColumnDefs);
  const [showTeamSettingsDialog, setShowTeamSettingsDialog] = useState(false);
  const [showAdminDialog, setShowAdminDialog] = useState(false);
  const [weblabIds, setWeblabIds] = useState<number[]>([]);
  const [showUsersDialog, setShowUsersDialog] = useState(false);
  const [showRequestAccessDialog, setShowRequestAccessDialog] = useState(false);
  const [teamSettings, setTeamSettings] = useState<TeamSettings>({
    id: 0,
    name: "",
    custom_attributes: JSON.parse("{}"),
  });
  const [usersSettings, setUsersSettings] = useState<Users>({
    team_id: 0,
    current_user_id: 0,
    user_ids: [],
    pending_user_ids: [],
  });
  const gridOptions: any = {
    defaultColDef: {
      resizable: true,
      wrapHeaderText: teamCustomAttributes.is_wrap_header_text,
      autoHeaderHeight: teamCustomAttributes.is_auto_header_height,
      wrapText: teamCustomAttributes.is_wrap_text,
      autoHeight: teamCustomAttributes.is_auto_height,
      cellDataType: false,
    },
    sideBar: {
      toolPanels: [
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
          toolPanelParams: {},
        },
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
          },
        },
      ],
      defaultToolPanel: ["filters"],
    },
    allowDragFromColumnsToolPanel: true,
    enableRangeSelection: true,
    enableFillHandle: true,
    fillHandleDirection: "y",
    rowHeight: 30,
  };

  useEffect(() => {
    if (currentTeamId > 0) {
      console.log("Team: [" + currentTeamId + "] meta is refreshing...");
      fetchMetadata().then((r) => {
        refresh();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeamId, metaRefreshFlag]);

  useEffect(() => {
    if (currentTeamId > 0 && teamConfig && teamConfig.custom_attributes) {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }
      console.log(
        "Team: [" +
          currentTeamId +
          "] data is refreshing with current year flag [" +
          showCurrentYear +
          "], show watch list [" +
          showWatchList +
          "], refresh flag [" +
          refreshFlag +
          "], is open[" +
          isOpen +
          "]"
      );
      fetchWeblabs().then((r) => {
        setTimeout(() => {
          if (selectedRowData) {
            selectRow(selectedRowData?.id);
          }
        }, 500);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCurrentYear, showWatchList, refreshFlag, isOpen]);

  const getColumnState = async () => {
    let columnState = [];
    if (currentTeamId > 0) {
      try {
        const getTeamByTeamIdInput: GetTeamByTeamIdInput = {
          team_id: currentTeamId,
        };
        const teamData = await client.graphql({
          query: getTeamByTeamId,
          variables: {
            input: getTeamByTeamIdInput,
          },
        });
        // @ts-ignore
        if (teamData.data.getTeamByTeamId.custom_attributes) {
          const customAttributes = JSON.parse(
            teamData.data.getTeamByTeamId.custom_attributes
          );
          if (customAttributes.hasOwnProperty("column_state")) {
            columnState = customAttributes.column_state;
          }
        }
      } catch (error) {
        console.error(error);
        setError(error);
      }
    }
    return columnState;
  };

  const onGridReady = useCallback((params: GridReadyEvent) => {
    console.log("in onGridReady");
    gridRef.current = params.api;
    params.api.deselectAll(); // Deselect rows
    params.api.setFilterModel(null); // Reset all filters
    setSelectedRowData(null);
  }, []);

  const onRowDoubleClicked = (event: any) => {
    setEditingRow(event.data);
    setIsModalOpen(true);
  };

  const handleUpdate = async (updatedItem: WeblabRowType) => {
    try {
      const weblab: UpdateWeblabInput = {
        id: updatedItem.weblab_id,
        name: updatedItem.name,
        description: updatedItem.description,
        bar_raiser_wiki: updatedItem.bar_raiser_wiki,
        global_init_name: updatedItem.global_init_name,
        experiment_type_id: updatedItem.experiment_type_id,
        page_type_id: updatedItem.page_type_id,
        owners: updatedItem.owners,
        weblab_team: updatedItem.weblab_team,
        deep_dive_link: updatedItem.deep_dive_link,
      };
      await client.graphql({
        query: updateWeblab,
        variables: {
          input: weblab,
        },
      });
      const teamWeblab: UpdateTeamWeblabInput = {
        id: updatedItem.id,
        weblab_id: updatedItem.weblab_id,
        team_id: currentTeamId,
        user_id: updatedItem.user_id,
        marketplace_id: updatedItem.marketplace_id,
        smoke_test: updatedItem.smoke_test,
        control: updatedItem.control,
        treatment: updatedItem.treatment,
        start_date: updatedItem.start_date,
        analysis_duration: updatedItem.analysis_duration,
        is_gating: updatedItem.is_gating,
        device_type_id: updatedItem.device_type_id,
        weblab_status_id: updatedItem.status_id,
        launch_date: updatedItem.launch_date,
        actual_launch_date: updatedItem.actual_launch_date,
        gccp_impact: updatedItem.gccp_impact,
        gccp_lift: updatedItem.gccp_lift,
        gccp_prob: updatedItem.gccp_prob,
        net_cp_impact: updatedItem.net_cp_impact,
        net_cp_lift: updatedItem.net_cp_lift,
        net_cp_prob: updatedItem.net_cp_prob,
        ops_impact: updatedItem.ops_impact,
        ops_lift: updatedItem.ops_lift,
        ops_prob: updatedItem.ops_prob,
        sp_clicks_impact: updatedItem.sp_clicks_impact,
        sp_clicks_lift: updatedItem.sp_clicks_lift,
        sp_clicks_prob: updatedItem.sp_clicks_prob,
        sp_revenue_impact: updatedItem.sp_revenue_impact,
        sp_revenue_lift: updatedItem.sp_revenue_lift,
        sp_revenue_prob: updatedItem.sp_revenue_prob,
        paid_units_impact: updatedItem.paid_units_impact,
        paid_units_lift: updatedItem.paid_units_lift,
        paid_units_prob: updatedItem.paid_units_prob,
        csales_impact: updatedItem.csales_impact,
        csales_lift: updatedItem.csales_lift,
        csales_prob: updatedItem.csales_prob,
        sb_revenue_impact: updatedItem.sb_revenue_impact,
        sb_revenue_lift: updatedItem.sb_revenue_lift,
        sb_revenue_prob: updatedItem.sb_revenue_prob,
        acos: updatedItem.acos,
        analysis_end_date: updatedItem.analysis_end_date,
        analysis_start_date: updatedItem.analysis_start_date,
        coverage: updatedItem.coverage,
        cpc: updatedItem.cpc,
        ctr: updatedItem.ctr,
        gating_weblab: updatedItem.gating_weblab,
        irrelevance_rate_control: updatedItem.irrelevance_rate_control,
        irrelevance_rate_treatment: updatedItem.irrelevance_rate_treatment,
        is_chosen_baseline_treatment: updatedItem.is_chosen_baseline_treatment,
        is_paste_manual_reports: updatedItem.is_paste_manual_reports,
        job_ids: updatedItem.job_ids,
        pirrel_human_relevance_audit: updatedItem.pirrel_human_relevance_audit,
        rev_share: updatedItem.rev_share,
        revenue_impact_planned_weblab:
          updatedItem.revenue_impact_planned_weblab,
        is_manual_weblab: updatedItem.is_manual_weblab,
        last_updated_by: configs.userIDsDict[currentUserName],
      };
      await client.graphql({
        query: updateTeamWeblab,
        variables: {
          input: teamWeblab,
        },
      });
      fetchWeblabs();
    } catch (error) {
      console.error(error);
      setError(error);
    }
    setIsModalOpen(false);
  };

  const openAddRowModalToAdd = () => {
    //editingRow = null;
    setEditingRow(null);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsCopy(false);
    setIsModalOpen(false);
  };

  const openTeamSettingsDialog = () => {
    setShowTeamSettingsDialog(true);
  };

  const openAdminDialog = async () => {
    await fetchTeamsData();
    let filteredRowData: WeblabRowType[] = [];
    gridRef.current?.forEachNodeAfterFilter((node) => {
      filteredRowData.push(node.data);
    });
    const ids = filteredRowData.map((item) => item.id);
    setWeblabIds(ids);
    setShowAdminDialog(true);
  };

  const openUsersDialog = () => {
    setShowUsersDialog(true);
  };

  async function fetchTeamsData() {
    try {
      const teamsData = await client.graphql({
        query: getTeams,
      });
      const teams = teamsData.data.getTeams;
      setTeams(teams);

      const createUserInput: CreateUserInput = {
        user_name: currentUserName,
      };
      const userData = await client.graphql({
        query: createUser,
        variables: {
          input: createUserInput,
        },
      });
      const currentUserId = userData.data.createUser.id;
      setCurrentUserId(currentUserId);
    } catch (err) {
      console.error(err);
    }
  }

  const openRequestAccessDialog = async () => {
    await fetchTeamsData();
    setShowRequestAccessDialog(true);
  };

  const refresh = () => {
    setRefresh(!refreshFlag);
  };
  const metaRefresh = () => {
    setMetaRefresh(!metaRefreshFlag);
  };
  const selectRow = (id: any) => {
    setTimeout(() => {
      if (gridRef.current?.isDestroyed() === false) {
        gridRef.current?.forEachNode(function (node) {
          if (node.data.id === id) {
            node.setSelected(true);
          }
        });
      }
    }, 500);
  };

  const handleTeamSettingsClose = () => {
    setShowTeamSettingsDialog(false);
  };
  const handleAdminClose = () => {
    refresh();
    setShowAdminDialog(false);
  };
  const handleUsersClose = () => {
    metaRefresh();
    setShowUsersDialog(false);
  };

  const handleRequestAccessClose = () => {
    setShowRequestAccessDialog(false);
  };

  async function fetchWeblabs() {
    setMessage("Please wait, refreshing...");
    try {
      if (configs.teamsCustomDict[currentTeamId]) {
        let allColumns = initialColumnDefs;
        // @ts-ignore
        configs.teamsCustomDict[currentTeamId].columns.forEach((element) => {
          // @ts-ignore
          const column = {
            headerName: element.label,
            field: "additional_columns." + element.col_name,
            sortable: true,
            filter: true,
            editable: true,
            cellDataType: element.type.toLowerCase(),
            cellStyle: { backgroundColor: "#ffecb3", color: "#000" }, // Yellow background
          };
          allColumns.splice(-5, 0, column); // keep favorite, delete, edit, and comments at last elements
        });
        await sortColumnsDefs(allColumns);
        const getTeamPreferencesInput: GetTeamPreferencesInput = {
          team_id: currentTeamId,
        };

        let weblabs: WeblabRowType[] = [];
        let currentWeblabs: WeblabRowType[] = [];
        let offset: number = 0;
        const LIMIT: number = 800;
        do {
          currentWeblabs = [];
          const listTeamWeblabsInput: ListTeamWeblabsInput = {
            team_id: currentTeamId,
            limit: LIMIT,
            offset: offset,
            is_current_year: showCurrentYear,
          };
          const weblabData = await client.graphql({
            query: listWeblabs,
            variables: {
              input: getTeamPreferencesInput,
              teamInput: listTeamWeblabsInput,
            },
          });
          currentWeblabs = weblabData.data.listTeamWeblabs.map(
            (teamWeblab: TeamWeblab) => {
              let teamPreference = null;
              if (weblabData.data.getTeamPreferences != null) {
                teamPreference = weblabData.data.getTeamPreferences.filter(
                  (item) =>
                    item.team_weblab_id === teamWeblab.id &&
                    item.team_id === teamWeblab.team_id
                )[0];
              }
              const weblab = weblabData.data.listWeblabs.filter(
                (item) => item.id === teamWeblab.weblab_id
              )[0];

              return {
                id: teamWeblab.id,
                team_id: teamWeblab.team_id,
                weblab_team: weblab.weblab_team,
                user_name: configs.usersDict[teamWeblab.user_id],
                user_id: teamWeblab.user_id,
                bar_raiser_wiki: weblab.bar_raiser_wiki!,
                name: weblab.name,
                weblab_id: weblab.id,
                is_shared_weblab: false,
                description: weblab.description,
                owners: weblab.owners,
                global_init_name: weblab.global_init_name!,
                deep_dive_link: weblab.deep_dive_link!,
                experiment_type:
                  configs.experimentTypesDict[weblab.experiment_type_id],
                experiment_type_id: weblab.experiment_type_id,
                marketplace:
                  configs.marketplacesDict[teamWeblab.marketplace_id],
                marketplace_id: teamWeblab.marketplace_id,
                smoke_test: teamWeblab.smoke_test!,
                control: teamWeblab.control!,
                treatment: teamWeblab.treatment!,
                start_date: teamWeblab.start_date,
                start_date_date:
                  teamWeblab.start_date !== "" && teamWeblab.start_date !== null
                    ? new Date(teamWeblab.start_date)
                    : null,
                analysis_duration: teamWeblab.analysis_duration,
                page_type: configs.pageTypesDict[weblab.page_type_id],
                page_type_id: weblab.page_type_id,
                is_gating: teamWeblab.is_gating,
                device: configs.deviceTypesDict[teamWeblab.device_type_id],
                device_type_id: teamWeblab.device_type_id,
                status: configs.statusesDict[teamWeblab.weblab_status_id],
                status_id: teamWeblab.weblab_status_id,
                launch_date: teamWeblab.launch_date,
                launch_date_date:
                  teamWeblab.launch_date !== "" &&
                  teamWeblab.launch_date !== null
                    ? new Date(teamWeblab.launch_date)
                    : null,
                actual_launch_date: teamWeblab.actual_launch_date,
                actual_launch_date_date:
                  teamWeblab.actual_launch_date !== "" &&
                  teamWeblab.actual_launch_date !== null
                    ? new Date(teamWeblab.actual_launch_date)
                    : null,
                gccp_impact: teamWeblab.gccp_impact,
                gccp_lift: teamWeblab.gccp_lift,
                gccp_prob: teamWeblab.gccp_prob,
                net_cp_impact: teamWeblab.net_cp_impact,
                net_cp_lift: teamWeblab.net_cp_lift,
                net_cp_prob: teamWeblab.net_cp_prob,
                ops_impact: teamWeblab.ops_impact,
                ops_lift: teamWeblab.ops_lift,
                ops_prob: teamWeblab.ops_prob,
                sp_clicks_impact: teamWeblab.sp_clicks_impact,
                sp_clicks_lift: teamWeblab.sp_clicks_lift,
                sp_clicks_prob: teamWeblab.sp_clicks_prob,
                sp_revenue_impact: teamWeblab.sp_revenue_impact,
                sp_revenue_lift: teamWeblab.sp_revenue_lift,
                sp_revenue_prob: teamWeblab.sp_revenue_prob,
                paid_units_impact: teamWeblab.paid_units_impact,
                paid_units_lift: teamWeblab.paid_units_lift,
                paid_units_prob: teamWeblab.paid_units_prob,
                csales_impact: teamWeblab.csales_impact,
                csales_lift: teamWeblab.csales_lift,
                csales_prob: teamWeblab.csales_prob,
                sb_revenue_impact: teamWeblab.sb_revenue_impact,
                sb_revenue_lift: teamWeblab.sb_revenue_lift,
                sb_revenue_prob: teamWeblab.sb_revenue_prob,
                acos: teamWeblab.acos,
                analysis_end_date: teamWeblab.analysis_end_date,
                analysis_end_date_date:
                  teamWeblab.analysis_end_date !== "" &&
                  teamWeblab.analysis_end_date !== null
                    ? new Date(teamWeblab.analysis_end_date)
                    : null,
                analysis_start_date: teamWeblab.analysis_start_date,
                analysis_start_date_date:
                  teamWeblab.analysis_start_date !== "" &&
                  teamWeblab.analysis_start_date !== null
                    ? new Date(teamWeblab.analysis_start_date)
                    : null,
                coverage: teamWeblab.coverage,
                cpc: teamWeblab.cpc,
                ctr: teamWeblab.ctr,
                gating_weblab: teamWeblab.gating_weblab,
                irrelevance_rate_control: teamWeblab.irrelevance_rate_control,
                irrelevance_rate_treatment:
                  teamWeblab.irrelevance_rate_treatment,
                is_chosen_baseline_treatment:
                  teamWeblab.is_chosen_baseline_treatment,
                is_paste_manual_reports: teamWeblab.is_paste_manual_reports,
                job_ids: teamWeblab.job_ids,
                pirrel_human_relevance_audit:
                  teamWeblab.pirrel_human_relevance_audit,
                rev_share: teamWeblab.rev_share,
                revenue_impact_planned_weblab:
                  teamWeblab.revenue_impact_planned_weblab,
                is_manual_weblab: teamWeblab.is_manual_weblab,
                team_preference_id: teamPreference?.id || null,
                additional_columns: JSON.parse(
                  teamPreference?.additional_columns || "{}"
                ),
                favorite: teamPreference?.watch_flag || false,
                comments: teamPreference?.comments,
                last_updated_by: teamWeblab.last_updated_by,
                last_updated_by_user_name:
                  configs.usersDict[teamWeblab.last_updated_by],
                updated_ts: covertUtcToLocal(teamWeblab.updated_ts),
                created_ts: teamWeblab.created_ts,
              };
            }
          );
          weblabs.push(...currentWeblabs);
          offset += currentWeblabs.length;
        } while (currentWeblabs.length === LIMIT);
        if (showWatchList) {
          // TODO: we can get data from API rather than filter on frontend for better performance
          setRowData(weblabs.filter((item) => item.favorite));
        } else {
          setRowData(weblabs);
        }
      }
    } catch (err) {
      console.error(err);
      setError(err);
    }
    setMessage(null);
  }

  function resetFilters() {
    gridRef.current!.setFilterModel(null);
    console.log("The filters have been reset");
  }

  function exportData() {
    gridRef.current!.exportDataAsExcel();
    console.log("The team data has been exported");
  }
  function getDate(value: string): Date {
    let dateParts = value.split("/");
    return new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
      Number(dateParts[2])
    );
  }

  const dateFilterParams: IMultiFilterParams = {
    filters: [
      {
        filter: "agDateColumnFilter",
        filterParams: {
          convertValuesToStrings: true,
          comparator: (filterDate: Date, cellValue: Date) => {
            if (cellValue == null) return -1;
            return cellValue.getTime() - filterDate.getTime();
          },
        } as IDateFilterParams,
      },
      {
        filter: "agSetColumnFilter",
        filterParams: {
          convertValuesToStrings: true,
          comparator: (a: string, b: string) => {
            if (a === null || b === null) return 0;
            return getDate(a).getTime() - getDate(b).getTime();
          },
        } as ISetFilterParams,
      },
    ],
  };

  function applyFilters(columns: ColDef[]) {
    columns.forEach((element: any, index: number) => {
      if (element.filter === true) {
        //element.floatingFilter = true;
        switch (element.cellDataType) {
          case "number":
            element.filter = "agMultiColumnFilter";
            element.filterParams = {
              filters: [
                {
                  filter: "agNumberColumnFilter",
                },
                {
                  filter: "agSetColumnFilter",
                },
              ],
            } as IMultiFilterParams;
            break;
          case "date":
            element.filter = "agMultiColumnFilter";
            element.filterParams = dateFilterParams;
            break;
          default:
            element.filter = "agMultiColumnFilter";
        }
      }
      if (element.hide === true || element.headerName === "") {
        element.suppressColumnsToolPanel = true;
      } else {
        if (element.cellDataType === "number") {
          element.enableValue = true;
          element.aggFunc = "sum";
        } else {
          element.enableRowGroup = true;
        }
      }
      // @ts-ignore
      element.wrapText = teamSettings.custom_attributes.is_wrap_text;
      element.wrapHeaderText =
        // @ts-ignore
        teamSettings.custom_attributes.is_wrap_header_text;
      // @ts-ignore
      element.autoHeight = teamSettings.custom_attributes.is_auto_height;
      element.autoHeaderHeight =
        // @ts-ignore
        teamSettings.custom_attributes.is_auto_header_height;
    });
  }
  async function sortColumnsDefs(columns: ColDef[]) {
    type OrderMap = {
      [key: string]: [number, number];
    };
    let orderMap: OrderMap = {};
    let columnState = await getColumnState();
    if (columnState && columnState.length > 1) {
      columnState.forEach((element: any, index: number) => {
        orderMap[element.colId] = [index, element.width];
      });
      columns.sort((a, b) => {
        a.width = a.field! in orderMap ? orderMap[a.field!][1] : a.width;
        b.width = b.field! in orderMap ? orderMap[b.field!][1] : b.width;
        const orderA =
          a.field! in orderMap ? orderMap[a.field!][0] : Number.MAX_VALUE;
        const orderB =
          b.field! in orderMap ? orderMap[b.field!][0] : Number.MAX_VALUE;
        return orderA - orderB;
      });
    }
    applyFilters(columns);
    setColumnsDefs(columns);
  }

  async function fetchMetadata() {
    try {
      const getUsersByTeamIdInput: GetUsersByTeamIdInput = {
        team_id: currentTeamId,
      };
      const metaData = await client.graphql({
        query: getMetadata,
        variables: {
          input: getUsersByTeamIdInput,
        },
      });
      // @ts-ignore
      const meta: Metadata = metaData.data;
      setMetadata(meta);

      // populate users
      meta.getUsers.forEach((item) => {
        configs.usersDict[item.id] = item.user_name;
        configs.userIDsDict[item.user_name] = item.id;
      });
      // populate teams
      meta.getTeams.forEach((item) => {
        configs.teamsDict[item.id] = item.name;
        configs.teamsCustomDict[item.id] = JSON.parse(item.custom_attributes);
      });
      // populate team_user_map
      configs.teamsUsersDict[currentTeamId] = [];
      configs.usersTeamsDict[configs.userIDsDict[currentUserName]] = [];
      meta.getUsersByTeamId.forEach((item) => {
        configs.teamsUsersDict[currentTeamId].push(item.id);
      });
      configs.teamsPendingUsersDict[currentTeamId] = [];
      meta.getPendingUsersByTeamId.forEach((item) => {
        configs.teamsPendingUsersDict[currentTeamId].push(item.id);
      });
      const teamData: TeamSettings = {
        id: currentTeamId,
        name: configs.teamsDict[currentTeamId],
        custom_attributes: configs.teamsCustomDict[currentTeamId],
      };
      setTeamSettings(teamData);
      setShowCurrentYear(
        // @ts-ignore
        teamData.custom_attributes.is_default_to_current_year_view
      );
      const usersData: Users = {
        team_id: currentTeamId,
        current_user_id: configs.userIDsDict[currentUserName],
        user_ids: configs.teamsUsersDict[currentTeamId],
        pending_user_ids: configs.teamsPendingUsersDict[currentTeamId] || [],
      };
      setUsersSettings(usersData);
      // populate page types
      meta.getPageTypes.forEach((item) => {
        configs.pageTypesDict[item.id] = item.name;
      });
      // populate device types
      meta.getDeviceTypes.forEach((item) => {
        configs.deviceTypesDict[item.id] = item.name;
      });
      // populate experiment types
      meta.getExperimentTypes.forEach((item) => {
        configs.experimentTypesDict[item.id] = item.name;
      });
      // populate weblab statuses
      meta.getWeblabStatuses.forEach((item) => {
        configs.statusesDict[item.id] = item.name;
        configs.statusIDsDict[item.name] = item.id;
      });
      // populate marketplaces
      meta.getMarketplaces.forEach((item) => {
        configs.regionDict[item.region] = [];
      });
      meta.getMarketplaces.forEach((item) => {
        configs.marketplacesDict[item.marketplace_id] = item.marketplace;
        configs.marketplaceIdsDict[item.marketplace] = item.marketplace_id;
        configs.marketplaceDisplayOrderDict[item.marketplace] =
          item.display_order;
        configs.regionDict[item.region].push(item.marketplace);
      });
      setConfigs(configs);
    } catch (err) {
      console.error(err);
      setError(err);
    }
  }

  const handleTeamSettingsSave = async (item: TeamSettings) => {
    try {
      const team: UpdateTeamInput = {
        id: item.id,
        name: item.name,
        custom_attributes: JSON.stringify(item.custom_attributes),
      };
      const teamData = await client.graphql({
        query: updateTeam,
        variables: {
          input: team,
        },
      });
      setInfoMessage(
        "Please refresh your browser for the team setting changes to take effect!"
      );
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const handleUsersSave = async (item: Users) => {
    try {
      const teamUserMap: UpdateTeamUserMapInput = {
        team_id: currentTeamId,
        user_ids: item.user_ids.join(),
      };
      await client.graphql({
        query: updateTeamUserMap,
        variables: {
          input: teamUserMap,
        },
      });
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const handleRequestAccessSave = async (item: Users) => {
    try {
      const teamUserMap: UpdateTeamUserMapInput = {
        team_id: currentTeamId,
        user_ids: item.user_ids.join(),
      };
      await client.graphql({
        query: updateTeamUserMap,
        variables: {
          input: teamUserMap,
        },
      });
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const handleSave = async (newItem: WeblabRowType) => {
    try {
      if (newItem.id !== undefined && newItem.id > 0) {
        await handleUpdate(newItem);
        let teamPreference: UpdateTeamPreferenceInput;
        teamPreference = {
          id: newItem.team_preference_id!,
          team_id: newItem.team_id,
          team_weblab_id: newItem.id,
          watch_flag: newItem.favorite,
          comments: newItem.comments!,
          additional_columns: JSON.stringify(newItem.additional_columns),
        };
        await client.graphql({
          query: updateTeamPreference,
          variables: {
            input: teamPreference,
          },
        });
      } else {
        let weblabId = 0;
        if (newItem.weblab_id === null || newItem.weblab_id === 0) {
          const weblab: CreateWeblabInput = {
            id: newItem.weblab_id,
            name: newItem.name,
            description: newItem.description,
            bar_raiser_wiki: newItem.bar_raiser_wiki,
            global_init_name: newItem.global_init_name,
            experiment_type_id: newItem.experiment_type_id,
            page_type_id: newItem.page_type_id,
            owners: newItem.owners,
            weblab_team: newItem.weblab_team,
            deep_dive_link: newItem.deep_dive_link,
            is_shared_weblab: newItem.is_shared_weblab,
          };
          const weblabData = await client.graphql({
            query: createWeblab,
            variables: {
              input: weblab,
            },
          });
          weblabId = weblabData.data.createWeblab.id;
        } else {
          // update weblab
          const weblab: UpdateWeblabInput = {
            id: newItem.weblab_id,
            name: newItem.name,
            description: newItem.description,
            bar_raiser_wiki: newItem.bar_raiser_wiki,
            global_init_name: newItem.global_init_name,
            experiment_type_id: newItem.experiment_type_id,
            page_type_id: newItem.page_type_id,
            owners: newItem.owners,
            weblab_team: newItem.weblab_team,
            deep_dive_link: newItem.deep_dive_link,
            is_shared_weblab: newItem.is_shared_weblab,
          };
          const weblabData = await client.graphql({
            query: updateWeblab,
            variables: {
              input: weblab,
            },
          });
          weblabId = weblabData.data.updateWeblab.id;
        }
        setMessage("Please wait, processing bulk add...");
        for (const marketplace of newItem.marketplaces!) {
          for (const treatment of newItem.treatments!) {
            const index: number = newItem.marketplaces!.indexOf(marketplace);
            if (false && newItem.is_manual_weblab === false) {
              // TODO: remove 'false' once we have a faster API
              // auto weblab
              const getAnalysisStartDatesInput: GetAnalysisStartDatesInput = {
                name: newItem.name,
                marketplace_id: configs.marketplaceIdsDict[marketplace],
                control: newItem.control,
                treatment: treatment,
                analysis_duration: newItem.analysis_duration!,
              };
              console.log(getAnalysisStartDatesInput);
              const analysisStartDatesData = await client.graphql({
                query: getAnalysisStartDates,
                variables: {
                  input: getAnalysisStartDatesInput,
                },
              });
              if (
                analysisStartDatesData.data.getAnalysisStartDates.length >= 1
              ) {
                const analysisStartDate =
                  analysisStartDatesData.data.getAnalysisStartDates[0]
                    .analysis_start_date;
                console.log(analysisStartDate);
                const [month, day, year] = analysisStartDate.split("/");
                const formattedDate = `${year}-${month}-${day}`;

                const getWeblabMetricsInput: GetWeblabMetricsInput = {
                  name: newItem.name,
                  marketplace_id: configs.marketplaceIdsDict[marketplace],
                  control: newItem.control,
                  treatment: treatment,
                  start_date: formattedDate,
                  analysis_duration: newItem.analysis_duration!,
                };
                console.log(getWeblabMetricsInput);
                const weblabMetricsData = await client.graphql({
                  query: getWeblabMetrics,
                  variables: {
                    input: getWeblabMetricsInput,
                  },
                });
                console.log(weblabMetricsData);
                if (weblabMetricsData.data.getWeblabMetrics.length >= 1) {
                  const metrics = weblabMetricsData.data.getWeblabMetrics[0];
                  newItem.analysis_start_date = metrics.start_date;
                  newItem.analysis_end_date = metrics.analysis_end_date;
                  newItem.job_ids = metrics.job_ids.replace(/^\[|\]$/g, "");
                  newItem.sp_revenue_prob = metrics.sp_revenue_prob;
                  newItem.sp_revenue_lift = metrics.sp_revenue_lift;
                  newItem.sp_revenue_impact = Math.ceil(
                    metrics.sp_revenue_impact
                  );
                  newItem.ops_prob = metrics.ops_prob;
                  newItem.ops_lift = metrics.ops_lift;
                  newItem.ops_impact = Math.ceil(metrics.ops_impact);
                  newItem.gccp_prob = metrics.gccp_prob;
                  newItem.gccp_lift = metrics.gccp_lift;
                  newItem.gccp_impact = Math.ceil(metrics.gccp_impact);
                  newItem.net_cp_prob = metrics.net_cp_prob;
                  newItem.net_cp_lift = metrics.net_cp_lift;
                  newItem.net_cp_impact = Math.ceil(metrics.net_cp_impact);
                  newItem.sp_clicks_prob = metrics.sp_clicks_prob;
                  newItem.sp_clicks_lift = metrics.sp_clicks_lift;
                  newItem.sp_clicks_impact = Math.ceil(
                    metrics.sp_clicks_impact
                  );
                  newItem.paid_units_prob = metrics.paid_units_prob;
                  newItem.paid_units_lift = metrics.paid_units_lift;
                  newItem.paid_units_impact = Math.ceil(
                    metrics.paid_units_impact
                  );
                  newItem.csales_prob = metrics.csales_prob;
                  newItem.csales_lift = metrics.csales_lift;
                  newItem.csales_impact = Math.ceil(metrics.csales_impact);
                  newItem.sb_revenue_prob = metrics.sb_revenue_prob;
                  newItem.sb_revenue_lift = metrics.sb_revenue_lift;
                  newItem.sb_revenue_impact = Math.ceil(
                    metrics.sb_revenue_impact
                  );
                }
              }
            }
            const teamWeblab: CreateTeamWeblabInput = {
              is_manual_weblab: newItem.is_manual_weblab,
              acos: newItem.acos,
              analysis_end_date: newItem.analysis_end_date,
              analysis_start_date: newItem.analysis_start_date,
              coverage: newItem.coverage,
              cpc: newItem.cpc,
              ctr: newItem.ctr,
              gating_weblab: newItem.gating_weblab,
              irrelevance_rate_control: newItem.irrelevance_rate_control,
              irrelevance_rate_treatment: newItem.irrelevance_rate_treatment,
              is_chosen_baseline_treatment:
                newItem.is_chosen_baseline_treatment,
              is_paste_manual_reports: newItem.is_paste_manual_reports,
              job_ids: newItem.job_ids,
              pirrel_human_relevance_audit:
                newItem.pirrel_human_relevance_audit,
              rev_share: newItem.rev_share,
              revenue_impact_planned_weblab:
                newItem.revenue_impact_planned_weblab,
              weblab_id: weblabId,
              team_id: currentTeamId,
              user_id: configs.userIDsDict[currentUserName],
              marketplace_id: configs.marketplaceIdsDict[marketplace],
              smoke_test: newItem.smoke_test,
              control: newItem.control,
              treatment: treatment,
              start_date: newItem.start_date,
              analysis_duration: newItem.analysis_duration,
              is_gating: newItem.is_gating,
              device_type_id: newItem.device_type_id,
              weblab_status_id: newItem.status_id,
              launch_date: newItem.launch_date,
              actual_launch_date: newItem.actual_launch_date,
              gccp_impact: newItem.gccp_impact,
              gccp_lift: newItem.gccp_lift,
              gccp_prob: newItem.gccp_prob,
              net_cp_impact: newItem.net_cp_impact,
              net_cp_lift: newItem.net_cp_lift,
              net_cp_prob: newItem.net_cp_prob,
              ops_impact: newItem.ops_impact,
              ops_lift: newItem.ops_lift,
              ops_prob: newItem.ops_prob,
              sp_clicks_impact: newItem.sp_clicks_impact,
              sp_clicks_lift: newItem.sp_clicks_lift,
              sp_clicks_prob: newItem.sp_clicks_prob,
              sp_revenue_impact: newItem.sp_revenue_impact,
              sp_revenue_lift: newItem.sp_revenue_lift,
              sp_revenue_prob: newItem.sp_revenue_prob,
              paid_units_impact: newItem.paid_units_impact,
              paid_units_lift: newItem.paid_units_lift,
              paid_units_prob: newItem.paid_units_prob,
              csales_impact: newItem.csales_impact,
              csales_lift: newItem.csales_lift,
              csales_prob: newItem.csales_prob,
              sb_revenue_impact: newItem.sb_revenue_impact,
              sb_revenue_lift: newItem.sb_revenue_lift,
              sb_revenue_prob: newItem.sb_revenue_prob,
              last_updated_by: configs.userIDsDict[currentUserName],
            };
            const teamWeblabData = await client.graphql({
              query: createTeamWeblab,
              variables: {
                input: teamWeblab,
              },
            });
            // create
            let teamPreference: CreateTeamPreferenceInput;
            teamPreference = {
              team_id: newItem.team_id,
              team_weblab_id: teamWeblabData.data.createTeamWeblab.id,
              watch_flag: newItem.favorite,
              comments: newItem.comments!,
              additional_columns: JSON.stringify(newItem.additional_columns),
            };
            await client.graphql({
              query: createTeamPreference,
              variables: {
                input: teamPreference,
              },
            });
          }
        }
        setMessage(null);
      }
    } catch (error) {
      console.error(error);
      setError(error);
    }
    isUpdatedByForm.current = true;
    refresh();
    //setRowData([...rowData, newItem]);
  };

  const removeSelected = async () => {
    setIsDialogOpen(true);
  };

  const editSelected = () => {
    if (gridRef.current?.isDestroyed() === false) {
      const selectedData = gridRef.current?.getSelectedRows()!;
      if (!selectedData || selectedData.length === 0) return; // didn't select anything, return
      setEditingRow(selectedData[0]);
      setIsModalOpen(true);
    }
  };

  const openAddRowModalToCopy = () => {
    const selectedData = gridRef.current?.getSelectedRows()!;
    if (selectedData.length === 0) {
      setError("Please select a row!!!");
      return; // didn't select anything, return
    }
    setIsCopy(true);
    setEditingRow(selectedData[0]);
    setIsModalOpen(true);
  };

  const onToggleFavorite = async (params: any) => {
    console.log("---onToggleFavorite--start---");
    try {
      if (params.data.team_preference_id === null) {
        // create
        let teamPreference: CreateTeamPreferenceInput;
        teamPreference = {
          team_id: params.data.team_id,
          team_weblab_id: params.data.id,
          watch_flag: !params.data.favorite,
          comments: params.data.comments,
          additional_columns: JSON.stringify(params.data.additional_columns),
        };

        await client.graphql({
          query: createTeamPreference,
          variables: {
            input: teamPreference,
          },
        });
      } else {
        let teamPreference: UpdateTeamPreferenceInput;
        teamPreference = {
          id: params.data.team_preference_id,
          team_id: params.data.team_id,
          team_weblab_id: params.data.id,
          watch_flag: !params.data.favorite,
          comments: params.data.comments,
          additional_columns: JSON.stringify(params.data.additional_columns),
        };

        await client.graphql({
          query: updateTeamPreference,
          variables: {
            input: teamPreference,
          },
        });
      }
      params.data.favorite = !params.data.favorite;
      console.log("---onToggleFavorite---end--");
      gridRef.current!.refreshCells({ force: true });
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleConfirm = async () => {
    setIsDialogOpen(false);
    const selectedData = gridRef.current?.getSelectedRows()!;
    if (selectedData.length === 0) return; // didn't select anything, return
    try {
      const deleteTeamWeblabInput: DeleteTeamWeblabInput = {
        id: selectedData[0].id,
      };
      await client.graphql({
        query: deleteTeamWeblab,
        variables: {
          input: deleteTeamWeblabInput,
        },
      });
      fetchWeblabs();
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const selectedData = event.api.getSelectedRows();
    setSelectedRowData(selectedData[0] || null);
  };

  const onPinnedRowDataChanged = (params: PinnedRowDataChangedEvent) => {
    // @ts-ignore
    if (gridRef.current?.getDisplayedRowCount() > 0) {
      gridRef.current?.ensureIndexVisible(0);
    }
  };

  function isCustomizedColumn(col: string) {
    console.log("cell [" + col + "] value is changed");
    return col.substring(0, 19) === "additional_columns." || col === "comments";
  }

  // @ts-ignore
  const onCellValueChanged = async (event) => {
    if (isUpdatedByForm.current && event.column.getColId() !== "status") {
      isUpdatedByForm.current = false;
      return;
    }
    isUpdatedByForm.current = false;
    try {
      if (!isCustomizedColumn(event.column.getColId())) {
        // normal column change
        event.data.status_id = configs.statusIDsDict[event.data.status];
        event.data.start_date =
          event.data.start_date_date === null
            ? ""
            : event.data.start_date_date.toLocaleDateString("en-US");
        event.data.launch_date =
          event.data.launch_date_date === null
            ? ""
            : event.data.launch_date_date.toLocaleDateString("en-US");
        event.data.actual_launch_date =
          event.data.actual_launch_date_date === null
            ? ""
            : event.data.actual_launch_date_date.toLocaleDateString("en-US");
        await handleUpdate(event.data);
      } else {
        // customized column change
        if (event.data.team_preference_id === null) {
          let teamPreference: CreateTeamPreferenceInput;
          teamPreference = {
            team_id: event.data.team_id,
            team_weblab_id: event.data.id,
            watch_flag: event.data.favorite,
            comments: event.data.comments,
            additional_columns: JSON.stringify(event.data.additional_columns),
          };

          await client.graphql({
            query: createTeamPreference,
            variables: {
              input: teamPreference,
            },
          });
        } else {
          let teamPreference: UpdateTeamPreferenceInput;
          teamPreference = {
            id: event.data.team_preference_id,
            team_id: event.data.team_id,
            team_weblab_id: event.data.id,
            watch_flag: event.data.favorite,
            comments: event.data.comments,
            additional_columns: JSON.stringify(event.data.additional_columns),
          };

          await client.graphql({
            query: updateTeamPreference,
            variables: {
              input: teamPreference,
            },
          });
        }
      }
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const onGridPreDestroyed = useCallback(() => {
    console.log("The grid is destroying!");
    setRowData([]);
    setSelectedRowData(null);
  }, []);

  const onFilterChanged = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.deselectAll();
      const firstRowNode = gridRef.current.getDisplayedRowAtIndex(0);
      if (firstRowNode) {
        firstRowNode.setSelected(true);
      }
    }
  }, []);

  async function onColumnChanged(params: any) {
    try {
      let customAttributes: JSON = configs.teamsCustomDict[currentTeamId];
      // @ts-ignore
      customAttributes["column_state"] = gridRef.current!.getColumnState();
      const team: UpdateTeamInput = {
        id: currentTeamId,
        name: configs.teamsDict[currentTeamId],
        custom_attributes: JSON.stringify(customAttributes),
      };
      await client.graphql({
        query: updateTeam,
        variables: {
          input: team,
        },
      });
    } catch (error) {
      console.error(error);
      setError(error);
    }
  }

  const onFirstDataRendered = async (params: any) => {
    gridRef.current?.getDisplayedRowAtIndex(0)?.setSelected(true);
    const firstRowNode = gridRef.current?.getDisplayedRowAtIndex(0);
    if (firstRowNode) {
      selectRow(firstRowNode.data.id);
    }
  };

  if (currentTeamId === 0)
    return (
      isOpen && (
        <div>
          <div>You don't have permission to access any team's data.</div>
          <button onClick={openRequestAccessDialog}>Request Access</button>
          <RequestAccessDialog
            isOpen={showRequestAccessDialog}
            onSave={handleRequestAccessSave}
            onClose={handleRequestAccessClose}
            currentUserId={currentUserId}
            initialData={teams}
          />
        </div>
      )
    );

  function handleGlobalSearch() {
    gridRef.current!.setGridOption(
      "quickFilterText",
      (document.getElementById("global-search-text-box") as HTMLInputElement)
        .value
    );
  }

  function toggleHelpSection() {
    setShowHelp(!showHelp);
  }

  return (
    isOpen && (
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "87.8%",
        }}
      >
        <Resizable
          style={{
            flex: showHelp ? "0 0 80%" : "0 0 100%",
            //flexGrow: showHelp ? "0" : "0",
            //flexShrink: showHelp ? "0" : "0",
            //flexBasis: showHelp ? "80%" : "100%",
          }}
        >
          <Resizable
            defaultSize={{
              width: "100%",
              height: "99.6%",
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              borderStyle: "double",
            }}
          >
            <Resizable
              defaultSize={{
                height: "50%",
                width: "100%",
              }}
              enable={{
                top: true,
                right: false,
                bottom: true,
                left: false,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
              }}
              style={{
                overflow: "auto",
                background: "#eee",
                border: "1px solid gray",
              }}
            >
              <DetailsPanel selectedRowData={selectedRowData} />
            </Resizable>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                minHeight: "15%",
                border: "1px solid gray",
                width: "99.6%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "5px",
                  marginTop: "7px",
                  marginLeft: "5px",
                  marginRight: "5px",
                  height: "25px",
                  width: "99.6%",
                }}
              >
                <button onClick={openAddRowModalToAdd}>Add weblab</button>
                <Tooltip title="Copy the selected weblab" placement={"top"}>
                  <button onClick={openAddRowModalToCopy}>
                    Copy to add weblab
                  </button>
                </Tooltip>
                <Tooltip title="Show Current Year Only" placement={"top"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showCurrentYear}
                        onChange={(e) => setShowCurrentYear(e.target.checked)}
                        name={"showCurrentYear"}
                        color={"primary"}
                      />
                    }
                    label="Current Year"
                  />
                </Tooltip>
                <Tooltip title="Show Watch List Only" placement={"top"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showWatchList}
                        onChange={(e) => setShowWatchList(e.target.checked)}
                        name={"showWatchList"}
                        color={"primary"}
                      />
                    }
                    label="Watch List"
                  />
                </Tooltip>
                <Tooltip title="Refresh Data" placement={"top"}>
                  <IconButton onClick={refresh}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Clear Column Filters" placement={"top"}>
                  <IconButton onClick={resetFilters}>
                    <FilterListOffIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Export Data" placement={"top"}>
                  <IconButton onClick={exportData}>
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Users" placement={"top"}>
                  <IconButton onClick={openUsersDialog}>
                    <Badge
                      badgeContent={usersSettings.pending_user_ids.length}
                      color={"primary"}
                    >
                      <PeopleIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Help Center" placement={"top"}>
                  <IconButton
                    color={showHelp ? "primary" : "default"}
                    onClick={toggleHelpSection}
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Settings" placement={"top"}>
                  <IconButton onClick={openTeamSettingsDialog}>
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
                {userAttributes.role === "admin" && (
                  <Tooltip title="Admin Panel" placement={"top"}>
                    <IconButton onClick={openAdminDialog}>
                      <AdminPanelSettingsIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <input
                  type={"text"}
                  id="global-search-text-box"
                  onChange={handleGlobalSearch}
                  placeholder={"Search..."}
                  style={{ width: "300px" }}
                />
              </div>
              <div
                className="ag-theme-quartz"
                style={{
                  flex: 1,
                  minHeight: "calc(15% - 25px)",
                  background: "#fff",
                  overflow: "auto",
                  border: "1px solid gray",
                }}
              >
                <AgGridReact
                  key={currentTeamId} // update the key to force a new instance
                  columnDefs={columnDefs}
                  rowData={rowData}
                  onGridReady={onGridReady}
                  rowSelection={"single"}
                  onRowDoubleClicked={onRowDoubleClicked}
                  animateRows={true}
                  onCellValueChanged={onCellValueChanged}
                  onSelectionChanged={onSelectionChanged}
                  onPinnedRowDataChanged={onPinnedRowDataChanged}
                  onColumnMoved={onColumnChanged}
                  onColumnResized={onColumnChanged}
                  onFilterChanged={onFilterChanged}
                  alwaysShowHorizontalScroll={true}
                  onFirstDataRendered={onFirstDataRendered}
                  gridOptions={gridOptions}
                  onGridPreDestroyed={onGridPreDestroyed}
                />
              </div>
              <AddWeblabModal
                isOpen={isModalOpen}
                onSave={handleSave}
                onClose={handleModalClose}
                metaData={metadata!}
                configs={configs}
                initialData={editingRow}
                currentTeamName={teamConfig.name}
                currentTeamId={currentTeamId}
                currentUserAttributes={userAttributes}
                isCopy={isCopy}
              ></AddWeblabModal>
              <ConfirmationDialog
                isOpen={isDialogOpen}
                onConfirm={handleConfirm}
                onClose={handleClose}
                message="Are you sure you want to delete this weblab?"
              />
              <TeamSettingsDialog
                isOpen={showTeamSettingsDialog}
                metaData={metadata!}
                onSave={handleTeamSettingsSave}
                onClose={handleTeamSettingsClose}
                configs={configs}
                initialData={teamSettings}
              />
              <AdminDialog
                isOpen={showAdminDialog}
                metaData={metadata!}
                onClose={handleAdminClose}
                configs={configs}
                initialData={teamSettings}
                currentUserName={currentUserName}
                currentUserId={currentUserId}
                currentTeamId={currentTeamId}
                ids={weblabIds}
              />
              <UsersDialog
                isOpen={showUsersDialog}
                metaData={metadata!}
                onSave={handleUsersSave}
                onClose={handleUsersClose}
                configs={configs}
                initialData={usersSettings}
              />
              <ErrorModal error={error} onClose={() => setError(null)} />
              <MessageModal message={message} />
              <InfoModal
                title={"Saved successfully!"}
                info={infoMessage}
                onClose={() => setInfoMessage(null)}
              />
            </div>
          </Resizable>
        </Resizable>
        {showHelp && <HelpCenter />}
      </div>
    )
  );
};

export default WeblabGridComponent;
